module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-126651057-2","head":true,"respectDNT":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Minesweeper","description":"JS minesweeper","keywords":"minesweeper, js, gatsby","display":"minimal-ui","theme_color":"#d4d0c8","background_color":"#3a6ea5","icon":"src/images/minesweeper-icon.png","lang":"en-US","start_url":"/"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
